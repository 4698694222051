import React from 'react'
import Figure from './Figure'

const serializers = {
  types: {
    authorReference: ({node}) => <span>{node.author.name}</span>,
    // TODO: Add productReference handler
    mainImage: Figure
  }
}

export default serializers
